let highlightPartFadeOnView = ( st, windowHeight ) => {

  jQuery( '.highlight-paragraph-part' ).each( function() {
    jQuery( this ).find( '.content' ).fadeOnView( st, windowHeight );
  });

};

let contentOnlyFadeOnView = ( st, windowHeight ) => {

  jQuery( '.content-only-part' ).each( function() {
    jQuery( this ).find( '.content' ).fadeOnView( st, windowHeight );
  });

};

let itemListFadeOnView = ( st, windowHeight ) => {
  let startPoint = 400;

  jQuery( '.item-list-part' ).each( function() {
    jQuery( this ).find( '.single-item' ).fadeOnView( st, windowHeight, startPoint );
  });

};

let threeColItemListFadeOnView = ( st, windowHeight ) => {
  let startPoint = 400;

  jQuery( '.three-col-item-list-part' ).each( function() {
    jQuery( this ).find( '.single-item' ).fadeOnView( st, windowHeight, startPoint );
  });

};

let twoColFadeOnView = ( st, windowHeight ) => {

  jQuery( '.two-column-with-title-part' ).each( function() {
    jQuery( this ).find( '.col' ).fadeOnView( st, windowHeight );
  });

};

let textImgFadeOnView = ( st, windowHeight ) => {
  let startPoint = 200;

  jQuery( '.text-and-image-part' ).each( function() {
    jQuery( this ).find( '.chr-text-and-image-container' )
      .fadeOnView( st, windowHeight, startPoint );
  });

};

let fillWidthImgFadeOnView = ( st, windowHeight ) => {
  let startPoint = 300;

  jQuery( '.full-width-image' ).each( function() {
    jQuery( this ).fadeOnView( st, windowHeight, startPoint );
  });

};

let serviceListFadeOnView = ( st, windowHeight ) => {
  let interval = 300;
  let defaultStartPoint = 200;

  if ( ! jQuery( '.services-items-part' ).length ) {
    return;
  }

  jQuery( '.services-items-container' ).each( function() {
    let offsetTop = jQuery( this ).offset().top;
    let time = 0;

    if ( offsetTop < st + windowHeight - defaultStartPoint ) {

      jQuery( this ).find( '.single-item' ).each( function() {

        let $current = jQuery( this );

        setTimeout( function() {
          $current.addClass( 'show' ).delay( time );
        }, time );

        time = time + interval;

      });

    }

  });
};

let quoteGroupFadeOnView = ( st, windowHeight ) => {
  let startPoint = 200;

  jQuery( '.quotes-group-part' ).each( function() {
    jQuery( this ).find( 'h3.quote span' ).fadeOnView( st, windowHeight, startPoint );
  });

};

let twoColTestimonialFadeOnView = ( st, windowHeight ) => {
  let startPoint = 400;

  jQuery( '.two-column-testimonial-part' ).each( function() {
    jQuery( this ).find( '.two-column-testimonial-container' )
      .fadeOnView( st, windowHeight, startPoint );
  });

};

let testimonialSliderFadeOnView = ( st, windowHeight ) => {

  let startPoint = 200;

  jQuery( '.testimonial-slider-part' ).each( function() {
    jQuery( this ).find( '.testimonial-slider-container' )
      .fadeOnView( st, windowHeight, startPoint );
  });

};

let textImgGrpFadeOnView = ( st, windowHeight ) => {
  let startPoint = 100;

  jQuery( '.chr-single-text-and-image-container' ).each( function() {
    jQuery( this ).fadeOnView( st, windowHeight, startPoint );
  });

};

let toggleFaqList = () => {

  jQuery( '.single-part .single-faq .question' ).click( ( event ) => {
    jQuery( event.currentTarget ).toggleClass( 'opened' );
    jQuery( event.currentTarget ).next().slideToggle();
  });

};

jQuery( document ).ready( () => {
  toggleFaqList();

  /*Handle some scroll events*/
  jQuery( window ).scroll( () => {

    let windowHeight = jQuery( window ).height();
    let st = jQuery( window ).scrollTop();

    highlightPartFadeOnView( st, windowHeight );
    contentOnlyFadeOnView( st, windowHeight );
    itemListFadeOnView( st, windowHeight );
    threeColItemListFadeOnView( st, windowHeight );
    twoColFadeOnView( st, windowHeight );
    textImgFadeOnView( st, windowHeight );
    fillWidthImgFadeOnView( st, windowHeight );
    serviceListFadeOnView ( st, windowHeight );
    quoteGroupFadeOnView( st, windowHeight );
    twoColTestimonialFadeOnView( st, windowHeight );
    testimonialSliderFadeOnView( st, windowHeight );
    textImgGrpFadeOnView( st, windowHeight );
  }).scroll();

});
