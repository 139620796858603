const getPixels = require( "get-pixels" );
const { extractColors } = require( 'extract-colors' );

function roundNumber( num, allowDecimal = false ) {
  const total = num * 100;
  if ( allowDecimal ) {
    return total.toFixed( 2 );
  }
  return total.toFixed( 0 );
}
  
function sortData( data ) {
  data.sort( ( first, second ) => second.area - first.area );
  return data;
}

function generatePallete( sortedResults ) {
  return sortedResults.forEach( ( result ) => {
    jQuery( ".pallete-wrapper" ).append( 
      '<div class="item-color" style="background-color:' +
       result.hex +
        '" data-color=\'' +
        JSON.stringify( result ) +
        '\'></div>'
    );
  });
}

function rgb2cmyk( red, green, blue ) {
  let computedC = 0;
  let computedM = 0;
  let computedY = 0;
  let computedK = 0;
   
  const pRed = parseInt( ( String( red ) ).replace( /\s/g, '' ), 10 );
  const pGreen = parseInt( ( String( green ) ).replace( /\s/g, '' ), 10 );
  const pBlue = parseInt( ( String( blue ) ).replace( /\s/g, '' ), 10 );
   
  if ( pRed === 0 && pGreen === 0 && pBlue === 0 ) {
    computedK = 1;
    return [
      0, 
      0, 
      0, 
      1
    ];
  }
   
  computedC = 1 - ( pRed / 255 );
  computedM = 1 - ( pGreen / 255 );
  computedY = 1 - ( pBlue / 255 );
   
  const minCMY = Math.min( computedC, 
    Math.min( computedM, computedY ) );
  computedC = ( computedC - minCMY ) / ( 1 - minCMY );
  computedM = ( computedM - minCMY ) / ( 1 - minCMY );
  computedY = ( computedY - minCMY ) / ( 1 - minCMY );
  computedK = minCMY;
   
  return [
    roundNumber( computedC ), 
    roundNumber( computedM ), 
    roundNumber( computedY ), 
    roundNumber( computedK )
  ];
}

function displayColorInfo( data ) {
  jQuery( ".color-info" ).empty( );

  jQuery( ".color-info" ).append( '<p id="hex">HEX: ' +
    data.hex +
    '</p>'
  );

  jQuery( ".color-info" ).append( '<p id="rgba">RGBA: rgba( ' +
   data.red + ', ' +
    data.green + ', ' +
    data.blue + ', ' +
    '1 )</p>'
  );

  jQuery( ".color-info" ).append( 
    '<p id="cmyk">CMYK: cymk( ' +
    rgb2cmyk( data.red, data.green, data.blue ) +
    ' )</p>'
  );

  if ( ! jQuery( ".picker-wrapper" ).hasClass( 'show' ) ) {
    jQuery( ".picker-wrapper" ).addClass( 'show' );
  }

}

function copyToClipboard( elementId ) {

  // Create a "hidden" input
  const aux = document.createElement( "input" );

  // Assign it the value of the specified element
  const value = document.getElementById( elementId ).innerHTML.split( ": " );
  aux.setAttribute( "value", value[1]);

  // Append it to the body
  document.body.appendChild( aux );

  // Highlight its content
  aux.select( );

  // Copy the highlighted text
  document.execCommand( "copy" );

  // Remove it from the body
  document.body.removeChild( aux );

}

function sleep( time ) {
  return new Promise( ( resolve ) => setTimeout( resolve, time ) );
}

jQuery( document ).on( 'click', '.color-info p', function() {
  jQuery( this ).addClass( 'copy' );
  copyToClipboard( jQuery( this ).attr( 'id' ) );
  sleep( 1000 ).then( ( ) => {
    jQuery( this ).removeClass( 'copy' );
  });
  
});

jQuery( '.myImage' ).on( 'change', function() {
  const file = this.files[0];
  if ( file ) {
    jQuery( ".pallete-wrapper" ).empty( );
    jQuery( ".color-info" ).empty( );

    jQuery( ".upload-container.title" ).addClass( "hide" );
    jQuery( ".upload-container.bottom" ).removeClass( "hide" );

    jQuery( ".upload-btn" ).text( "Upload new image" );
    jQuery( ".upload-container" ).append( '<div class="loader"></div>' );
        
    const reader = new FileReader( );
    reader.onload = function( event ) {

      //Preview image
      jQuery( '#imgPreview' ).attr( 'src', event.target.result );
      jQuery( ".imgPreview" ).removeClass( 'd-none' );

      // Generate palettes
      const src = event.target.result;
      getPixels( src, ( err, pixels ) => {
        if ( ! err ) {
          const data = [ ...pixels.data ];
          const width = Math.round( Math.sqrt( data.length / 4 ) );
          const height = width;

          extractColors({ data, 
            width, 
            height })
            .then( function( results ) {
              jQuery( '.loader' ).remove( );

              const sortedResults = sortData( results );

              jQuery( ".pallete-wrapper" ).removeClass( 'd-none' );
              jQuery( ".title" ).removeClass( 'd-none' );
                        
              generatePallete( sortedResults );
              displayColorInfo( sortedResults[0]);

            })
            .catch( console.log );
        }
      });

    };
    reader.readAsDataURL( file );

  }
});

jQuery( document ).on( 'click', '.pallete-wrapper .item-color', function() {
  const data = JSON.parse( jQuery( this ).attr( 'data-color' ) );
  
  displayColorInfo( data );

});
