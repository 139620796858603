let screenSize = require( '../utilities/media' );

let fixedCategoryList = () => {

  let menuHeight = jQuery( '.chr-header' ).height();
  let categoryListWrapperHeight = jQuery( '.blog-category-list-wrapper' ).height();
  let categoryListHeight = jQuery( '.blog-category-list' ).outerHeight( true );
  let categoryListWrapperOffsetTop;
  let st = jQuery( window ).scrollTop();

  if ( ! jQuery( 'section.blog-page' ).length ) {
    return;
  }

  categoryListWrapperOffsetTop = jQuery( '.blog-category-list-wrapper' ).offset().top;

  if ( st < categoryListWrapperOffsetTop - menuHeight ) {
    jQuery( '.blog-category-list.fixed' ).removeClass( 'fixed' );
  } else {
    jQuery( '.blog-category-list' ).addClass( 'fixed' );
  }

  if ( st < categoryListWrapperOffsetTop +
    categoryListWrapperHeight - categoryListHeight - menuHeight ) {
    jQuery( '.blog-category-list.bottom' ).removeClass( 'bottom' );
  } else {
    jQuery( '.blog-category-list' ).addClass( 'bottom' );
  }

};

let lockCategoryList = () => {
  let scrollMenuHeight = 57;
  let categoryListHeight = jQuery( '.blog-category-list' ).outerHeight( true );
  let windowH = jQuery( window ).height();

  if ( ! jQuery( 'section.blog-page' ).length ) {
    return;
  }

  if ( categoryListHeight < windowH - scrollMenuHeight ) {

    jQuery( '.blog-category-list.locked' ).removeClass( 'locked' );
  } else {

    jQuery( '.blog-category-list' ).addClass( 'locked' );
  }

};

let movePagination = () => {
  if ( ! jQuery( '.pagination' ).length ) {
    return;
  }

  jQuery( '.blog-page' ).append( jQuery( '.pagination' ).clone() );

};

let iframeAutoHeight = () => {

  let screenRatio = 1.7; //16:9

  jQuery( '.wysiwyg-wrapper iframe' ).each( function() {

    let iframeH = jQuery( this ).width() / screenRatio;
    jQuery( this ).css( 'height', iframeH + 'px' );

  });

};

let toggleSlideCategoryList = () => {

  jQuery( '.category-title' ).click( function() {

    if ( matchMedia( screenSize.desktop ).matches ) {
      return;
    }

    jQuery( this ).parent().find( 'ul' ).slideToggle();

  });

};

let covertHeadingStyle = () => {

  jQuery( '.single-blog-content .wysiwyg-wrapper h2' ).addClass( 'h3-style' );
  jQuery( '.single-blog-content .wysiwyg-wrapper h3' ).addClass( 'h4-style' );

};

jQuery( () => {

  movePagination();
  toggleSlideCategoryList();
  covertHeadingStyle();

  jQuery( window ).scroll( function() {
    fixedCategoryList();
  }).scroll();

  jQuery( window ).resize( function() {
    lockCategoryList();
    iframeAutoHeight();
  }).resize();

});
