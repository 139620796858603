let initBudgetRangeSlider = () => {
  const ZERO = 0;
  const $yourBudget = jQuery( '#your-budget' );
  if ( ZERO < $yourBudget.length ) {
    /*eslint-disable camelcase*/
    jQuery( '#your-budget' ).ionRangeSlider({
      type:                   'double',
      min:                    10,
      max:                    100,
      from:                   20,
      to:                     30,
      step:                   1,
      prefix:                 '$',
      postfix:                'K',
      values_separator:       '-',
      input_values_separator: '-'
    });
    /*eslint-enable camelcase*/
  }
};

let updateServicesOfInterest = () => {

  jQuery( '.project-form-list-item' ).click( function() {

    let servicesOfInterest = '';

    jQuery( this ).toggleClass( 'checked' );

    jQuery( '.project-form-list-item.checked' ).each( function() {
      servicesOfInterest += ' ' + jQuery( this ).find( '.project-form-list-item-label' ).text();
    });

    jQuery( 'input#services-of-interest' ).val( servicesOfInterest.trim() );

  });

};

jQuery( () => {
  initBudgetRangeSlider();
  updateServicesOfInterest();
});
