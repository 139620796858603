/*
 * Main JS file for JS imports.
 *
 * @author David James <david.james@chromatix.com.au>
 */
require( './layout/header' );
require( './layout/footer' );
require( './layout/slide-out-form' );
require( './pages/blog' );
require( './pages/contact' );
require( './components/testimonial-slider-part' );
require( './effects/functions' );
require( './effects/modules' );
require( './utilities/tracking-param-to-cookie.js' );
require( './utilities/form-ajax' );
require( './utilities/ie-detect' );
require( './utilities/recaptcha-callback' );
require( './utilities/lazy-load-video' );
require( './utilities/video' );
require( './utilities/scroll-to' );
require( './external/wp-embed.js' );
require( './color-picker.js' );

// Uncomment to enable browser detection tools on this site.
// For emergency use only. This should not be used unless there is no other way!
//require( './utilities/browser-detect' );
